import { AbstractControl, AsyncValidatorFn } from "@angular/forms";
import { ApiService } from "../services/api/api.service";

import { map, switchMap, first, concatMap } from "rxjs/operators";
import { timer, of, Observable } from "rxjs";

export class EmptyOrValidEmail {
  static createValidator(api: ApiService): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any }
    | null> => {
      const postData = {email: control.value};
      return validateEmailFn(api, postData, true);
    };
  }
}

export class ValidateEmail {

  static createValidator(api: ApiService, allowEmpty = true): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{ [key: string]: any }
    | null> => {
      const postData = {email: control.value};
      return validateEmailFn(api, postData, allowEmpty);
    };
  }
}

function validateEmailFn(api: ApiService, postData: any, allowEmpty = true) {
  return timer(500).pipe(
    concatMap(() => {
      postData.email = postData?.email?.trim();
      if (allowEmpty) {
        if (postData.email == null || !postData.email.length) {
          return of(null);
        }
      }
      return api.post("helper/validate-email", postData).pipe(
        map((res: any) => {
          if (res.wellFormed && res.validDomain) {
            return null;
          }
          const errors: any = {};
          if (!res.wellFormed) {
            errors.formatIssue = true;
          }
          if (!res.validDomain) {
            errors.domainIssue = true;
          }
          return errors;
        }, first())
      );
    })
  ).pipe(first());
}
